/*
 *  IsAndroidMobile
 *  IsAndroidNativeBrowser
 *  MinWidth
 *  MaxWidth
 *  DeviceOrientation
*/

var MediaQueries = (function() {

    // Note to developers: The following methods may or may not be necessary, but may be useful in certain situations
    
    // User Agent
    var navU = navigator.userAgent;

    return {

        IsAndroidMobile: function () {
            return navU.indexOf('Android') > -1 && navU.indexOf('Mozilla/5.0') > -1 && navU.indexOf('AppleWebKit') > -1;
        },

        IsAndroidNativeBrowser: function () {
            var regExAppleWebKit = new RegExp(/AppleWebKit\/([\d.]+)/),
                resultAppleWebKitRegEx = regExAppleWebKit.exec(navU),
                appleWebKitVersion = (resultAppleWebKitRegEx === null ? null : parseFloat(regExAppleWebKit.exec(navU)[1]));

            return this.IsAndroidMobile() && appleWebKitVersion !== null && appleWebKitVersion < 537;
        },

        MinWidth: function (minWidthValue) {
            if (Modernizr.mq !== undefined) {
                if (Modernizr.mq('only screen and (min-width: ' + minWidthValue + ')')) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else { return "\"Media Queries\" is not an included detect in your Modernizr build."; }
        },

        MaxWidth: function (maxWidthValue) {
            if (Modernizr.mq !== undefined) {
                if (Modernizr.mq('only screen and (max-width: ' + maxWidthValue + ')')) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else { return "\"Media Queries\" is not an included detect in your Modernizr build."; }
        },

        DeviceOrientation: function () {
            if (Modernizr.mq !== undefined) {
                if (Modernizr.mq('only screen and (orientation:portrait)')) {
                    return "portrait";
                }
                else if (Modernizr.mq('only screen and (orientation:landscape)')) {
                    return "landscape";
                }
                else { return null; }
            }
            else { return "\"Media Queries\" is not an included detect in your Modernizr build."; }
        },

        // Adds user agent (lets us detect browsers and style through CSS)
        AddUserAgentAsClass: function () {
            var doc = document.documentElement;
            doc.setAttribute('data-useragent', navigator.userAgent);
        },

        // Loads fastclick.js if we're on a touch device
        FastClickInit: function () {
            Modernizr.load({
                test: Modernizr.touch,
                yep: '/_static/vendor/fastclick/fastclick.min.js',
                nope: null,
                complete: function () {
                    if (Modernizr.touch) {
                        $(function () {
                            FastClick.attach(document.body, { ignoreClass : "^pac-" });
                        });
                    }
                }
            });
        }

    };

})();
